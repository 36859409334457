<template>
	<div>
		<seller-branch-filter
			class="mb-3"
			labelText="การเงินสาขา"
			@changeBranch="changeBranch($event)">
		</seller-branch-filter>

		<template v-if="fields">
			<seller-income-summary :fields="fields"></seller-income-summary>
			<seller-income-table :fields="fields" :branchId="branchId" class="mt-3"></seller-income-table>
		</template>
	</div>
</template>

<script>
import SellerBranchFilter from '@/components/seller/BranchFilter';
import SellerIncomeSummary from '@/components/seller/income/Summary';
import SellerIncomeTable from '@/components/seller/income/Table';
import SellerService from '@/services/sellerService';
import RouterPath from '@/router/path';
import Helper from '@/services/helper';

export default {
	components: {
		SellerBranchFilter,
		SellerIncomeSummary,
		SellerIncomeTable
	},
	data() {
		return {
			fields: null,
			branchId: Helper.getBranchSellerId(),
		}
	},
	mounted() {
		this.getSummaryIncome();
	},
	methods: {
		async getSummaryIncome() {
			let loader = this.$modalLoader.render();
			const result = await SellerService.getSummaryIncome(this.branchId);

			if (result.status == 401) {
				this.$router.push(RouterPath.SELLER_LOGIN);
			} else if (result.data) {
				this.fields = result.data;
			}

			loader.hide();
		},
		changeBranch(branchId) {
			this.branchId = branchId;
			this.getSummaryIncome();
		}
	}
}
</script>