<template>
	<div class="summary" v-if="fields">
		<h2>ภาพรวมการเงิน (คำสั่งซื้อ) <span>*รอบสัปดาห์ นับตั้งแต่วันจันทร์-วันอาทิตย์</span></h2>
		<div class="info">
			<div class="field">
				<label>วันนี้</label>
				<div class="data">{{ fields.today }}</div>
			</div>
			<div class="line-break"></div>
			<div class="field">
				<label>สัปดาห์นี้</label>
				<div class="data">{{ fields.thisWeek }}</div>
				<div class="note">
					<b-icon icon="caret-up-fill" class="icon green" v-if="fields.weekChange.direction === '+'"></b-icon>
					<b-icon icon="caret-down-fill" class="icon red" v-if="fields.weekChange.direction === '-'"></b-icon>
					{{ fields.weekChange.direction }}{{ fields.weekChange.value }} ({{ fields.weekChange.direction }}{{ fields.weekChange.percent }}%)
					<br />
					เทียบกับสัปดาห์ก่อนหน้า
				</div>
			</div>
			<div class="field">
				<label>สัปดาห์ก่อนหน้า</label>
				<div class="data">{{ fields.prevWeek }}</div>
			</div>
			<div class="field">
				<label>เดือนนี้</label>
				<div class="data">{{ fields.thisMonth }}</div>
				<div class="note">
					<b-icon icon="caret-up-fill" class="icon green" v-if="fields.monthChange.direction === '+'"></b-icon>
					<b-icon icon="caret-down-fill" class="icon red" v-if="fields.monthChange.direction === '-'"></b-icon>
					{{ fields.monthChange.direction }}{{ fields.monthChange.value }} ({{ fields.monthChange.direction }}{{ fields.monthChange.percent }}%)
					<br />
					เทียบกับเดือนก่อนหน้า
				</div>
			</div>
			<div class="field">
				<label>เดือนก่อนหน้า</label>
				<div class="data">{{ fields.prevMonth }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		fields: Object
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.summary {
	background: #fbac53;
	padding: 15px;

	h2 {
		font-size: 18px;
		font-family: 'Open Sans', 'Kanit';
		font-weight: bold;

		span {
			font-size: 13px;
			margin-left: 10px;

			@media only screen and (max-width: $screenSmall) {
				display: block;
				margin-left: 0;
			}
		}
	}

	.info {
		margin: 20px -10px 0;
		display: flex;
		flex-wrap: wrap;

		.line-break {
			width: 100%;
			display: none;

			@media only screen and (max-width: $screenExtraSmall) {
				display: block;
			}
		}

		.field {
			flex: 1;
			padding: 0 10px;

			@media only screen and (max-width: $screenSmall) {
				width: 33%;
				flex: none;
				margin-bottom: 10px;
			}

			@media only screen and (max-width: $screenExtraSmall) {
				width: 50%;
			}

			label {
				margin-bottom: 3px;

				@media only screen and (max-width: $screenSmall) {
					font-size: 13px;
				}
			}

			.data {
				background: #fff;
				padding: 7px 5px;
				text-align: right;
				font-size: 14px;
				height: 35px;
			}

			.note {
				font-size: 13px;
				line-height: 14px;
				display: block;
				margin-top: 7px;
				opacity: 0.7;

				@media only screen and (max-width: $screenLarge) {
					font-size: 11px;
					line-height: 12px;
				}

				.icon {
					margin-right: 2px;

					&.green {
						color: #02ab62;
					}

					&.red {
						color: #cc0000;
					}
				}
			}
		}
	}

	.bank {
		margin-top: 15px;
		padding-top: 15px;
		border-top: 1px solid #888;
		font-size: 18px;

		@media only screen and (max-width: $screenSmall) {
			font-size: 14px;
		}
	}
}
</style>