<template>
	<section>
		<div class="transaction">
			<h2>รายละเอียดทางการเงิน (การโอนเงิน)</h2>

			<div class="filter">
				<div class="form">
					<div class="field">
						<label>สถานะการโอนเงิน</label>
						<div class="input-data">
							<select
                                v-model="moneyTransferStatus"
								@change="getIncomeTransaction()"
								class="input-option">
								<option v-for="(item, index) in optionStatus" :key="index" :value="item.id">{{ item.name }}</option>
                            </select>
						</div>
					</div>
					<div class="field">
						<label>วันที่</label>
						<div class="date">
							<div class="input-date-icon">
								<datepicker
									v-model="fromDate"
									@input="getIncomeTransaction()"
									:input-class="[ 'input-date', 'square' ]"
									:language="calendarTH"
									:full-month-name="true"
									calendar-class="datepicker-box"
									placeholder="ตั้งแต่วันที่"
									format="dd/MM/yyyy">
								</datepicker>
								<b-icon icon="calendar"></b-icon>
							</div>

							<span>-</span>

							<div class="input-date-icon">
								<datepicker
									v-model="toDate"
									@input="getIncomeTransaction()"
									:input-class="[ 'input-date', 'square' ]"
									:language="calendarTH"
									:full-month-name="true"
									calendar-class="datepicker-box"
									placeholder="ถึงวันที่"
									format="dd/MM/yyyy">
								</datepicker>
								<b-icon icon="calendar"></b-icon>
							</div>
						</div>
					</div>
					<div class="field">
						<label>บัญชีธนาคารที่รับเงิน</label>
						<div class="input-data">
							<span class="static">{{ fields.bank }}</span>
						</div>
					</div>
				</div>

				<div class="operation">
					<button @click="exportExcel()">
						<b-icon icon="download"></b-icon>
					</button>
				</div>
			</div>

			<div class="loading" v-if="!isResultReady">
				<loader-content :isDisplay="true"></loader-content>
			</div>

			<div class="vue-dragscoll mt-2" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)" v-if="isResultReady">
				<table>
					<thead>
						<tr>
							<th>วันที่สั่งซื้อ</th>
							<th>วันทีติดตั้ง/จัดส่ง</th>
							<th>หมายเลขคำสั่งซื้อ</th>
							<th class="text-end">ยอดรวม (บาท)</th>
							<th class="text-center">ประเภทการชำระเงิน</th>
							<th class="text-end">ค่าธรรมเนียม (1)</th>
							<th class="text-end">หัก ณ ที่จ่าย 3% (2)</th>
							<th class="text-end">ยอดโอนเงินสุทธิหลังหัก (1) (2)</th>
							<th class="text-center">สถานะการโอนเงิน</th>
							<th>วันที่โอนเงิน</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in resultList" :key="index">
							<td>{{ item.orderDate }}</td>
							<td>{{ item.serviceDate }}</td>
							<td>{{ item.orderNumber }}</td>
							<td class="text-end">{{ item.orderTotal }}</td>
							<td class="text-center">{{ item.paymentMethod }}</td>
							<td class="text-end">{{ item.totalFee }}</td>
							<td class="text-end">{{ item.withholdingTotal }}</td>
							<td class="text-end">{{ item.transferAmount }}</td>
							<td class="text-center" :class="{ 'text-danger': !item.isMoneyTranfer }">{{ item.moneyTransferStatus }}</td>
							<td>{{ item.moneyTransferDate }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="remark">
			*สถานะ "<span class="text-danger">รอโอน</span>" คือ ร้านค้าต้อง ออกเอกสาร ใบหัก ณ ที่จ่าย 3% <span class="text-danger">ลงวันที่รอโอนเงินที่ระบุ จำนวนเงินที่จ่าย - ค่าธรรมเนียม</span> ส่งมาที่อีเมล <a href="mailto:Account@yellowtire.com">Account@yellowtire.com</a> ก่อน 10.00 น. เพื่อเยลโลไทร์จะได้โอนเงินให้ต่อไป และต้องจัดส่งเอกสารตัวจริงทางไปรษณีย์
		</div>
		
		<paging
			@page="changePage($event)"
			:activePage="activePage"
			:totalPage="totalPage"
			class="mt-4">
		</paging>
	</section>
</template>

<script>
import { th } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import SellerService from '@/services/sellerService';
import Helper from '@/services/helper';
import MixinDragScroll from '@/mixins/dragScroll';

export default {
	mixins: [ MixinDragScroll ],
	data() {
		return {
			calendarTH: th,
			optionStatus: this.initOptionStatus(),
			moneyTransferStatus: -1,
			fromDate: null,
			toDate: null,
			isResultReady: false,
			activePage: 1,
			totalPage: 0,
			resultList: []
		}
	},
	props: {
		fields: Object,
		branchId: Number
	},
	watch: {
		branchId() {
			this.getIncomeTransaction();
		},
	},
	mounted() {
		this.initFilterDate();
		this.getIncomeTransaction();
	},
	methods: {
		initFilterDate() {
			const begin = moment().weekday(-7).add('d', 1);
			const end = moment().weekday(-7).add('d', 7);

			this.fromDate = begin.toDate();
			this.toDate = end.toDate();
		},
		initOptionStatus() {
			const option = [
				{ id: -1, name: 'ทั้งหมด' },
				{ id: 0, name: 'รอโอนเงิน' },
				{ id: 1, name: 'โอนแล้ว' },
			];

			return option;
		},
		async getIncomeTransaction() {
			this.isResultReady = false;
			this.resultList = [];
			
            const result = await SellerService.getIncomeTransaction(this.moneyTransferStatus, this.fromDate, this.toDate, this.activePage, this.branchId);

			this.isResultReady = true;
			this.totalPage = result.data.totalPage;
			this.resultList = result.data.resultList;
		},
		changePage(page) {
			this.activePage = page;
			this.getIncomeTransaction();
		},
		async exportExcel() {
            let loader = this.$modalLoader.render();
			const result = await SellerService.exportIncome(this.moneyTransferStatus, this.fromDate, this.toDate, this.branchId);
			loader.hide();

			if (result.data?.success) {
				Helper.fileDownloadByApi(result.data?.file);
			} else {
				this.$swal(Helper.warningAlert('ไม่สามารถดาวน์โหลดไฟล์ Excel ได้', result.data?.message));
			}
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.transaction {
	background: #f2f2f2;

	.loading {
		height: 300px;
	}

	h2 {
		font-size: 22px;
		font-family: 'Open Sans', 'Kanit';
		font-weight: bold;
		padding: 15px;
	}

	.filter {
		padding: 0 15px 15px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		.form {
			.field {
				display: flex;
				margin-top: 5px;

				label {
					font-size: 14px;
					margin: 0;
					width: 150px;

					@media only screen and (max-width: $screenSmall) {
						display: none;
					}
				}

				.input-data {
					width: 200px;

					@media only screen and (max-width: $screenSmall) {
						width: 305px;
					}

					@media only screen and (max-width: $screenExtraSmall) {
						width: 244px;
					}
				}

				.input-option, .static {
					background: #fff;
					width: 100%;
					display: block;
					font-size: 14px;
					padding: 8px 15px;
					border: none;

					@media only screen and (max-width: $screenExtraSmall) {
						font-size: 12px;
					}
				}

				.input-option {
					cursor: pointer;
				}
			}
		}

		.date {
			display: flex;
			align-items: center;
			font-size: 14px;

			@media only screen and (max-width: $screenExtraSmall) {
				font-size: 12px;
			}

			.input-date-icon {
				width: 200px;

				@media only screen and (max-width: $screenSmall) {
					width: 140px;
				}

				@media only screen and (max-width: $screenExtraSmall) {
					width: 110px;
				}

				.bi {
					top: 28%;

					@media only screen and (max-width: $screenLarge) {
						top: 33%;
					}

					@media only screen and (max-width: $screenExtraSmall) {
						top: 34%;
					}
				}
			}

			span {
				margin: 0 10px;

				&:first-child {
					margin-left: 0; 
				}
			}
		}

		.operation {
			button {
				font-size: 18px;
				background: #fff;
				color: #555;
				cursor: pointer;
				border: 1px solid #ccc;

				&:hover {
					opacity: 0.5;
				}		
			}
		}
	}

	table {
        width: 100%;
        font-size: 13px;
		line-height: 15px;

		@media only screen and (max-width: $screenSmall) {
			font-size: 12px;
			line-height: 14px;
		}

        thead {
            th {
				font-weight: normal;
                background: #fbac53;
				text-align: center;
				padding: 7px;
				min-width: 80px;
            }
        }

		tbody {
			td {
				border-bottom: 1px solid #999;
				padding: 15px 10px;
			}
		}
    }
}

.remark {
	font-size: 12px;
	margin-top: 10px;
}

::v-deep .input-date-icon {
    .input-date {
        padding: 8px 15px;
		border: none;
    }
}
</style>